.modal {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1300;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        background-color: #000;
        color: #000;
        width: 45%;
        height: auto;
        border-radius: 20px;

        .close {
            background-color: transparent;
            border: none;
            outline: none;
            width: 25px;
            height: 25px;
            top: 12px;
            right: calc(-100% + 50px);
            cursor: pointer;
            display: flex;
            position: relative;
            align-items: center;

            &:before,
            &:after {
                content: '';
                position: absolute;
                width: 2.5px;
                height: 24px;
                background-color: #fff;
            }
            &:before{
                transform: rotate(45deg);
            }
            &:after{
                transform: rotate(-45deg);
            }
        }
    }
}