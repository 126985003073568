body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


#spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh);
  position: fixed;
  background-color: #d1d1d1;
  opacity: 0.6;
  right: 0;
  left: 0;
  top: 0;
  z-index: 7000;
}

@media (min-width: 960px) {
  #spinner {
    height: calc(100vh);
    top: 0;
  }
}

#spinner i {
  color: black;
  z-index: 3;
  font-size: 30px;
  animation: rotate 2s linear infinite;
  fill: black;
  position: absolute;
}

.spinner i {
  animation: rotate 2s linear infinite;

}

.enablePrint {
  display: none;
}

@media print {
  .printHeader {
    position: absolute;
    margin-left: 30%;
  }

  .disablePrint {
    display: none;
  }

  .enablePrint {
    display: initial;
  }

  main.wt-hide {
    padding-left: 0;
  }

  .wt-register-logo {
    margin: 0 auto;
    display: block;
    max-height: 20vh
  }

  .id-simulation {
    padding: 70px;
    margin: 0 auto;
  }
}

#scrollbar {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 500px;
  padding: 40px;
  border: 1px solid rgb(235, 235, 235);
}