* {
  box-sizing: border-box;
}

html {
  position: relative;
  height: 100%;
  background-color: #f5f5f5;
}

.color-primary {
  color: #e86500;
}

.color-accent {
  color: #077e9f;
}

.color-increase {
  color: #6ec202;
}

.color-decrease {
  color: #ffebe2;
}

.square {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  right: 0px;
  background-color: #525252;
  border-radius: 50%;
}

.card-icon-square {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  opacity: 0.16;
  border-radius: 10px;
}

.card-icon {
  z-index: 10;
  position: absolute;
}

.card-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card-item-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chart-line-up {
  border-radius: 10px;
  padding: 9px 12px;
  margin-right: 15px;
}

.chart-line-up_increase {
  background-color: rgba(111, 194, 2, 0.166);
}

.chart-line-down {
  border-radius: 10px;
  padding: 9px 12px;
  margin-right: 15px;
}

.chart-line-down_decrease {
  background-color: rgba(255, 0, 0, 0.164);
}

.edit-rounded-area {
  background-color: rgba(111, 194, 2, 0.166);
}

.delete-rounded-area {
  background-color: rgba(255, 0, 0, 0.164);
}

.card-item-row-line {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* align-items: center; */
}

.arrows-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 250px;
  flex-direction: row;
}

.left-arrow {
  padding-right: 15px;
}

.right-arrow {
  padding-left: 70px;
}

.date-filter-text {
  padding-left: 70px;
}

.filter-button-group {
  padding-left: 230px;
}

.month-filter-button {
  border-radius: 60px;
}

.year-filter-button {
  border-radius: 60px;
}

#button-confirm {
  background-color: #f46c00;
  color: #ffff;
  border-radius: "10px";
  padding-top: "8px";
  padding-bottom: "8px"
}

.bankAccount-currentValue {
  color: #f46c00 !important;
  font-family: Akrobat, sans-serif;
  font-size: 20px !important;
  font-weight: 400;
  padding-top: 10px;
}

#title-currentValue {
  font-size: 21px;
  color: #5f5f5f;
  margin-right: 20px;
}

#filter-text {
  font-family: Akrobat, sans-serif;
  color: #ffffff !important;
  text-transform: none;
  font-size: 16px;
}

#text-filter {
  font-family: Arial, Helvetica, sans-serifs !important;
  color: #ffffff !important;
  text-transform: none;
  font-size: 20px;
}

.button-actionBar {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 42px;
  width: 170px;
}

.button-filter {
  color: #fff !important;
  background-color: #f46c00 !important;
  border-color: #f46c00 !important;
  border-radius: 0.25rem;
  height: 42px;
  width: 170px;
}

.save-button-icon {
  padding-right: 10px;
}

.button-large {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 42px;
  width: 250px;
}

.button-send {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 42px;
  width: 200px;
}

.button-show-docs {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 42px;
  width: 175px;
}

.simular-button {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 42px;
  width: 150px;
}

.status-area {
  display: flex;
  position: relative;
  padding-left: 1em;
  border-radius: 10px;
}

.space-area {
  padding-top: 30px;
}

.active-hide-menu-item-area {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 48px;
  left: 5px;
  background-color: #525252;
  border-radius: 10px;
}

.active-wide-menu-item-area {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 48px;
  left: 10px;
  background-color: #525252;
  border-radius: 10px;
  color: orange;
}

.active-wide-menu-item-area.active-wide-menu-item-area-sub-menu {
  width: 220px;
}

.active-wide-menu-item-area.active-wide-menu-item-area-sub-menu-item {
  width: 220px;
}

.wt-user {
  position: relative;
  display: block;
  width: 120px;
  height: 120px;
  margin: 10px auto;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.wt-user.wt-user-small {
  display: inline-block;
  width: 42px;
  height: 42px;
  margin: 0;
}

.wt-place {
  position: relative;
  display: block;
  width: 100%;
  margin: 10px auto;
  padding-bottom: 50%;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.wt-place.wt-place-options {
  height: 209px;
  padding-bottom: 0;
}

.wt-error-message {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  color: #666;
}

@media (min-width: 768px) {
  body {
    font-size: 16px;
  }
}

.wt-title {
  font-size: 1.2em;
  font-weight: 300;
  text-transform: uppercase;
}

em {
  color: inherit;
}

.wt-login-logo {
  display: block;
  max-width: 200px;
  margin-bottom: 20px;
}

.wt-login-info {
  font-size: 0.8em;
  display: block;
  width: 100%;
  max-width: 450px;
  margin: auto;
  padding: 20px 0;
  text-align: center;
}

.wt-login-info a {
  margin-left: 10px;
  text-decoration: none;
  color: white;
}

.wt-login-info a:first-child {
  margin-left: 0;
}

.wt-headerbar {
  height: 60px;
}

@media (min-width: 960px) {
  .wt-headerbar {
    height: 80px;
  }
}

/* .wt-headerbar-logo {
	display: block;
	width: 230px;
	height: 80px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 125px;
} */

.wt-headerbar-logo {
  display: block;
  width: 60px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px;
  background-color: #5f5f5f;
}

.wt-headerbar-content {
  padding: 0 16px;
}

@media (min-width: 960px) {
  .wt-headerbar-content {
    padding: 0 30px;
  }
}

.wt-content-title {
  font-size: 16px;
  color: white;
}

.wt-content-title button {
  font-size: 1em;
  cursor: pointer !important;
  text-decoration: none !important;
  color: white;
  border: 0;
  outline: none;
  background-color: transparent;
}

a[href] {
  cursor: pointer;
}

@media (min-width: 960px) {
  .wt-content-title {
    font-size: 24px;
  }

  .wt-content-title button {
    font-size: 24px;
  }
}

.wt-content-profile {
  font-size: 18px;
}

.wt-content-profile img {
  width: 35px;
  height: 35px;
}

@media (min-width: 960px) {
  .wt-content-profile img {
    width: 42px;
    height: 42px;
  }
}

.wt-aside-nav {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.wt-aside-nav>li {
  display: inline-block;
  margin-top: 25px;
}

.wt-aside-nav>li>a,
.wt-aside-nav>li>ul>li>span {
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  display: inline-block;
  height: 32px;
  padding-left: 20px;
  transition: 280ms;
  text-decoration: none;
  color: #666;
  border-left: 3px solid transparent;
}

.wt-aside-nav>li>a i,
.wt-aside-nav>li>ul>li>span i {
  margin-right: 10px;
}

.wt-aside-nav>li>a.uk-accordion-title::after,
.wt-aside-nav>li>ul>li>span.uk-accordion-title::after {
  content: none;
}

.wt-aside-nav>li>ul>li .uk-accordion-content a {
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  display: inline-block;
  height: 32px;
  padding-left: 60px;
  transition: 280ms;
  text-decoration: none;
  color: #666;
  border-left: 3px solid transparent;
}

.wt-aside-nav>li:hover>a,
.wt-aside-nav>li:hover>ul>li>span,
.wt-aside-nav>li>ul>li>ul>li:hover>a {
  color: white;
}

.wt-aside-nav>li>ul>li.uk-open>span,
.wt-aside-nav>li.active>ul>li.uk-open>ul>li.active>a {
  color: white;
}

.wt-aside-nav>li.active>a,
.wt-aside-nav>li.active>ul>li>span {
  color: white;
  border-color: #e86500;
}

.wt-aside-nav+.wt-aside-nav {
  margin: 25px 0;
}

.wt-hide {
  position: relative;
  display: block;
  width: 100%;
  height: calc(100% - 80px);
  /* Discount the toolbar */
  padding-left: 85px;
}

@media (min-width: 960px) {
  .wt-hide {
    padding-left: 85px;
  }
}

.wt-main {
  position: relative;
  display: block;
  width: 100%;
  height: calc(100% - 80px);
  /* Discount the toolbar */
}

@media (min-width: 960px) {
  .wt-main {
    padding-left: 245px;
  }
}

.wt-table th {
  font-size: 14px;
  font-weight: 400;
  color: #666;
}

.wt-table.uk-table-hover tbody tr:hover,
.wt-table.uk-table-hover>tr:hover {
  background: #e7e7e7;
}

.wt-recivables-icon {
  padding: 5px 0;
}

.wt-recivables-icon hr {
  display: none;
}

@media (min-width: 960px) {
  .wt-recivables-icon {
    padding: 40px 0;
  }

  .wt-recivables-icon hr {
    display: block;
  }
}

.wt-tab>.uk-active>a {
  border-color: #ff8203;
}

.wt-card {
  position: relative;
  display: block;
  width: 100%;
  padding: 5px;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.wt-card-small {
  left: 0%;
  right: 0%;
  top: 120px;
  bottom: -100%;
  background-color: white;
  border-radius: 24px;
  height: 100px;
  display: block;
}

.wt-card-small .fa-3x {
  font-size: 1.5em;
}

.wt-card .fa-3x {
  font-size: 1em;
}

.wt-card.wt-card-large {
  padding: 10px 5px;
}

@media (min-width: 1200px) {
  .wt-card .fa-3x {
    font-size: 1em;
  }

  .wt-card.wt-card-large {
    padding: 25px 10px;
  }
}

.wt-card-title {
  display: block;
  font-style: #ffffff;
}

.wt-card-title .small {
  font-size: 16px;
  font-weight: 700;
  line-height: 1em;
}

.wt-card-title .large {
  font-size: 24px;
  font-weight: 700;
  line-height: 1em !important;
}

@media (min-width: 1200px) {
  .wt-card-title .small {
    font-size: 18px;
  }

  .wt-card-title .large {
    font-size: 32px;
  }
}

.wt-v-divider {
  display: block;
  width: 1px;
  height: 100%;
  margin: auto;
  background-color: #ff8203;
}

.wt-card-holder {
  position: relative;
  display: block;
  height: 100%;
}

.wt-card-holder .wt-card {
  height: calc(100% - 30.8px);
}

@media (min-width: 960px) {
  .wt-card-holder .wt-card {
    height: calc(100% - 34px);
  }
}

.wt-button {
  cursor: pointer;
  transition: 280ms;
  color: white;
  border: 1px solid transparent;
  border-radius: 3px;
  outline: none;
  background-color: #077e9f;
}

.wt-button-filter {
  cursor: pointer;
  transition: 280ms;
  border: 1px solid transparent;
  border-radius: 10px;
  outline: none;
  background-color: #ffffff;
}

.wt-button.wt-button-danger {
  background-color: rgb(255, 127, 127);
}

.wt-button.wt-button-danger:disabled:hover {
  background-color: rgb(255, 127, 127);
}

.wt-button.wt-button-danger:hover {
  background-color: rgb(237, 129, 142);
}

.wt-button.wt-button-success {
  background-color: #4ee2c1;
}

.wt-button.wt-button-success:disabled:hover {
  background-color: #4ee2c1;
}

.wt-button.wt-button-success:hover {
  background-color: rgb(35, 133, 149);
}

.wt-button:hover {
  color: white;
  background-color: #055f78;
}

.wt-button:hover {
  color: white;
  background-color: #055f78;
}

.wt-button.wt-button-circle {
  display: inline-block;
  width: 42px;
  height: 42px;
  margin: 0;
  padding: 0;
  text-align: center;
  border: 0;
  border-radius: 50%;
}

.wt-button-circle.wt-button-circle--danger {
  background-color: #f0506d;
}

.wt-button.wt-button-circle i {
  line-height: 40px;
  text-align: center;
}

.wt-button.wt-button-circle svg {
  margin-top: 50%;
  margin-left: 50%;
  transform: translate(-50%, -50%);
}

.wt-button.wt-button-circle-small {
  width: 34px;
  height: 34px;
}

.wt-button.wt-button-circle-small i {
  line-height: 34px;
}

.wt-button.wt-button-ghost {
  color: #666;
  border-color: #077e9f;
  background-color: transparent;
}

.wt-button.wt-button-lowercase {
  text-transform: none;
}

.wt-button.uk-disabled {
  color: #ccc;
  background-color: #eee;
}

.wt-sorter,
.wt-button-link {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer !important;
  text-decoration: none;
  text-transform: uppercase;
  color: #666;
  border: 0;
  outline: none;
  background-color: transparent;
}

.wt-button-link {
  font-size: 1em;
}

.wt-link {
  transition: 280ms;
  color: #077e9f;
}

.wt-link:hover {
  color: #066b87;
}

.wt-form input[type="text"],
.wt-form input[type="email"],
.wt-form input[type="password"],
.wt-form input[type="number"],
.wt-form select {
  border-radius: 3px;
}

.wt-form input[type="text"]:focus,
.wt-form input[type="email"]:focus,
.wt-form input[type="password"]:focus,
.wt-form input[type="number"]:focus,
.wt-form select:focus {
  border-color: #ff8203;
  outline: none;
}

.wt-form input[type="text"].uk-disabled,
.wt-form input[type="email"].uk-disabled,
.wt-form input[type="password"].uk-disabled,
.wt-form input[type="number"].uk-disabled,
.wt-form select.uk-disabled {
  background-color: #eee;
}

.wt-form label {
  font-weight: 700;
  text-transform: uppercase;
  color: #666;
}

.fade-appear {
  opacity: 0.01;
}

.fade-appear.fade-appear-active {
  transition: opacity 0.5s ease-in;
  opacity: 1;
}

.map-pin {
  position: absolute;
  display: block;
  border-radius: 50%;
  background-color: #666;
  width: 18px;
  height: 18px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: 580ms;
  transform: scale(0.9);
}

.page-totalizer {
  border-top: 1.5px solid #e5e5e5;
}

.pdf-footer {
  border-top: 1.5px solid #ff8203;
}

.wt-button-accept {
  background-color: #32d296 !important;
}

.white {
  color: white !important;
}

.map-pin:before {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: rgba(102, 102, 102, 0.5);
  display: block;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  content: "";
  transform: translate(-50%, -50%);
  transition: 580ms;
  opacity: 0;
}

.map-pin:hover {
  background-color: #ff8203;
  transform: scale(1.2);
}

.map-pin:hover:before {
  opacity: 1;
  background-color: rgba(255, 130, 3, 0.5);
}

.wt-graph {
  background-color: whitesmoke;
  font-size: 10px;
}

.wt-graph-percent {
  padding-right: 10px;
}

.wt-graph-liner {
  position: relative;
  display: block;
  width: 1px;
  height: 100%;
  background-color: grey;
  margin-left: 5px;
}

.wt-graph-bar {
  width: 100%;
  height: 1px;
  animation: draw 1s ease-in-out;
}

.wt-graph-bar.orange {
  background-color: #ff8203;
}

.wt-graph-bar.blue {
  background-color: #077e9f;
}

.wt-graph-bar span {
  display: block;
  margin-top: -20px;
}

.wt-graph-device::after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-top: 4px;
  margin-left: 5px;
}

.wt-graph-device.ios::after {
  background-color: #ff8203;
}

.wt-graph-device.android::after {
  background-color: #077e9f;
}

.wt-graph-device.male::after {
  background-color: #ff8203;
}

.wt-graph-device.female::after {
  background-color: #077e9f;
}

.wt-graph-device.not-informed::after {
  background-color: #666b75;
}

@keyframes draw {
  0% {
    height: 0;
  }
}

.onoffswitch {
  position: relative;
  width: 60px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 30px;
  padding: 0;
  line-height: 30px;
  border: 2px solid #c3c3c3;
  border-radius: 30px;
  background-color: #ffffff;
  transition: background-color 0.3s ease-in;
}

.onoffswitch-label:before {
  content: "";
  display: block;
  width: 30px;
  margin: 0px;
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 28px;
  border: 2px solid #c3c3c3;
  border-radius: 30px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label {
  background-color: #077e9f;
}

.onoffswitch-checkbox:checked+.onoffswitch-label,
.onoffswitch-checkbox:checked+.onoffswitch-label:before {
  border-color: #077e9f;
}

.onoffswitch-checkbox:checked+.onoffswitch-label:before {
  right: 0px;
}

.hover-info-bank {
  position: relative;
}

.tooltip-bank {
  top: 20px;
  left: 150px;
  background-color: white;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.hover-info-bank:hover .tooltip-bank {
  opacity: 1;
}

.div-circle {
  display: inline-block;
  width: 84px;
  height: 84px;
  margin: 0;
  padding: 0;
  text-align: center;
  border: 0;
  border-radius: 50%;
  background-color: #35c350;
}

.div-circle i {
  line-height: 82px;
  text-align: center;
  color: white;
}

.div-circle svg {
  margin-top: 50%;
  margin-left: 50%;
  transform: translate(-50%, -50%);
}

.wt-card-small {
  width: fit-content !important;
  padding: 25px !important;
}

body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.wt-button-orange {
  background-color: #ff8203 !important;
}

.wt-button-danger {
  background-color: #f0506e !important;
}

#root,
.uk-offcanvas-content,
.main-content {
  width: 100%;
  height: 100%;
}

@media print {

  #root,
  .uk-offcanvas-content,
  .main-content {
    height: initial;
  }
}

.main-content {
  overflow-y: auto;
  padding: 10px 16px;
  position: relative;
}

.uk-badge.uk-badge-responsive {
  height: auto;
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

.pentagon-detail svg path {
  fill: #666;
  width: 50px;
  height: 50px;
}

.vertical-to-top {
  vertical-align: top !important;
}

.uk-table-middle td .vertical-to-top {
  vertical-align: top !important;
}

table td tbody tr .vertical-to-top {
  vertical-align: top !important;
}

.display-linebreak {
  white-space: pre-line;
  text-align: justify;
  text-justify: inter-word;
}